import React, { useState  } from "react";
import "./Navbar.css";
import logo from "../../assets/Green_and_White_Minimalist_Fashion_Logi-removebg-preview.png"
import {
  MagnifyingGlassIcon,
  UserIcon,
  HeartIcon,
  ShoppingCartIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

const Navbar = () => {
const [menuOpen, setMenuOpen] = useState(false);
const [isSareesMenuOpen, setSareesMenuOpen] = useState(false); // New state for Sarees submenu
const [isSuitMenuOpen, setSuitMenuOpen] = useState(false); // New state for Sarees submenu

  const handleAccount = () => {
    window.location.href = "/createaccount";
  };

  const handleAddToCart = () => {
    window.location.href = "/cart";
  };

  const handleHome = () => {
    window.location.href = "/";
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSareesMenu = () => {
    setSareesMenuOpen(!isSareesMenuOpen);
  };

  const toggleSuitMenu = () => {
    setSuitMenuOpen(!isSuitMenuOpen);
  }

  return (
    <>
      <div>
        {/* Navbar Header */}
        <div className="px-5 flex justify-between items-center ">
          <div className="flex space-x-3">
            {/* Menu Icon (Visible on small screens) */}
          <div className="sm:hidden cursor-pointer flex items-center" onClick={toggleMenu}>
              {menuOpen ? (
                <XMarkIcon className="h-6 w-6 text-gray-500" />
              ) : (
                <Bars3Icon className="h-6 w-6 text-gray-500" />
              )}
            </div>

          <div onClick={handleHome} className="cursor-pointer">
            <img className="w-40 max-sm:w-24" src={logo} alt="Logo" />
          </div>
          </div>

          {/* Search Bar (Hidden on small screens) */}
          <div className="hidden sm:flex">
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
              </span>
              <input
                type="text"
                placeholder="Search"
                className="pl-10 pr-4 py-1 border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>
          </div>

          {/* Icons (User, Heart, Cart) */}
          <div className="flex space-x-6 max-sm:space-x-3 items-center">
            <div className="cursor-pointer" onClick={handleAccount}>
              <UserIcon className="h-6 w-6 max-sm:w-5 max-sm:h-5 text-gray-500 hover:text-blue-500" />
            </div>

            <div className="cursor-pointer">
              <HeartIcon className="h-6 w-6 max-sm:w-5 max-sm:h-5 text-gray-500 hover:text-red-500" />
            </div>

            <div className="cursor-pointer" onClick={handleAddToCart}>
              <ShoppingCartIcon className="h-6 w-6 max-sm:w-5 max-sm:h-5 text-gray-500 hover:text-green-500" />
            </div>
          </div>
        </div>

        <hr />

        {/* Full Menu (Hidden on small screens) */}
        <div className={`sm:flex justify-center py-4 ${menuOpen ? "block" : "hidden"} sm:block`}>
          <ul className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-6 text-gray-600 cursor-pointer">
            <li className="relative group">
              <span className="text-gray-700">New Arrivals</span>
              <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-gray-500 transition-all duration-300 group-hover:w-full"></span>
            </li>
            <li className="relative group">
              <span className="text-gray-700">Online Exclusive</span>
              <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-gray-500 transition-all duration-300 group-hover:w-full"></span>
            </li>
            {/* Sarees Menu with Submenu */}
            <li className="relative group sm:static" onClick={toggleSareesMenu}>
              <span className="text-gray-700">Sarees</span>
              <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-gray-500 transition-all duration-300 group-hover:w-full"></span>
              {/* Submenu for small screens */}
              {isSareesMenuOpen && (
                <ul className="sm:hidden block px-4 py-2 bg-white shadow-lg rounded-lg z-10">
                  <li className="text-xs py-3 hover:bg-blue-100 cursor-pointer">Woven Sarees</li>
                  <li className="text-xs py-3 hover:bg-blue-100 cursor-pointer">Embroidery Sarees</li>
                  <li className="text-xs py-3 hover:bg-blue-100 cursor-pointer">Printed Sarees</li>
                  <li className="text-xs py-3 hover:bg-blue-100 cursor-pointer">Plain Sarees</li>
                  <li className="text-xs py-3 hover:bg-blue-100 cursor-pointer">Silk Sarees</li>
                </ul>
              )}
              {/* Submenu for larger screens */}
              <ul className="hidden sm:absolute top-4 sm:left-0 sm:mt-2 sm:w-44 sm:opacity-0 sm:group-hover:opacity-100 sm:group-hover:block transition-all duration-300 ease-in-out bg-white shadow-lg rounded-lg z-10">
                <li className="text-xs px-4 py-3 hover:bg-blue-100 cursor-pointer">Woven Sarees</li>
                <li className="text-xs px-4 py-3 hover:bg-blue-100 cursor-pointer">Embroidery Sarees</li>
                <li className="text-xs px-4 py-3 hover:bg-blue-100 cursor-pointer">Printed Sarees</li>
                <li className="text-xs px-4 py-3 hover:bg-blue-100 cursor-pointer">Plain Sarees</li>
                <li className="text-xs px-4 py-3 hover:bg-blue-100 cursor-pointer">Silk Sarees</li>
              </ul>
            </li>
            {/* Other Menu Items */}
            <li className="relative group sm:static" onClick={toggleSuitMenu}>
              <span className="text-gray-700">Suits</span>
              <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-gray-500 transition-all duration-300 group-hover:w-full"></span>
              {isSuitMenuOpen && (
                <ul className="sm:hidden block px-4 py-2 bg-white shadow-lg rounded-lg z-10">
                  <li className="text-xs py-3 hover:bg-blue-100 cursor-pointer">Stiched Suits Duppata</li>
                  <li className="text-xs py-3 hover:bg-blue-100 cursor-pointer">Unstiched Suits</li>
                </ul>
              )}
              {/* Submenu for larger screens */}
              <ul className="hidden sm:absolute top-4 sm:left-0 sm:mt-2 sm:w-44 sm:opacity-0 sm:group-hover:opacity-100 sm:group-hover:block transition-all duration-300 ease-in-out bg-white shadow-lg rounded-lg z-10">
                <li className="text-xs px-4 py-3 hover:bg-blue-100 cursor-pointer">Stiched Suits Duppata</li>
                <li className="text-xs px-4 py-3 hover:bg-blue-100 cursor-pointer">Unstiched Suits</li>
              </ul>
            </li>
            <li className="relative group">
              <span className="text-gray-700">Lehenga</span>
              <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-gray-500 transition-all duration-300 group-hover:w-full"></span>
            </li>
            <li className="relative group">
              <span className="text-gray-700">Dresses</span>
              <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-gray-500 transition-all duration-300 group-hover:w-full"></span>
            </li>
            <li className="relative group">
              <span className="text-gray-700">Fragrances</span>
              <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-gray-500 transition-all duration-300 group-hover:w-full"></span>
            </li>
            {/* Collection Menu with Submenu */}
            <li className="relative group">
              <span className="text-gray-700">Collection</span>
              <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-gray-500 transition-all duration-300 group-hover:w-full"></span>
              <ul className="hidden sm:absolute top-4 sm:left-0 sm:mt-2 sm:w-44 sm:opacity-0 sm:group-hover:opacity-100 sm:group-hover:block transition-all duration-300 ease-in-out bg-white shadow-lg rounded-lg z-10">
                <li className="text-xs px-4 py-3 hover:bg-blue-100 cursor-pointer">Utsav</li>
              </ul>
            </li>
          </ul>
        </div>
        <hr />
      </div>
    </>
  );
};

export default Navbar;
