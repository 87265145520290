import React from 'react'
import Slider from './Slider/Slider'
import Images from './Images/Images'
import Seller from './Seller/Seller'
import SellerOne from './SellerOne/SellerOne'
import Saree from './Saree/Saree'
import Show from './Show/Show'
import Text from './Text/Text'

const Home = () => {
  return (
    <>
      <Slider />
      <Images />
      <Seller />
      <SellerOne />
      <Saree />
      <Show />
      <Text />
    </>
  )
}

export default Home
