import React from 'react';
import './Signup.css';
import face from '../../assets/facebook.jpg';
import insta from '../../assets/instagram-icon.jpg';
import twit from '../../assets/pinterest-icon.jpg';
import youtube from '../../assets/youtube-icon.jpg';

import visa from '../../assets/visa.png';
import mastercard from '../../assets/master-card.png';
import amex from '../../assets/Amex-512.webp';
import paypal from '../../assets/PayPal.png';
import gpay from '../../assets/gpay.webp';

const Signup = () => {
  return (
    <div>
      <div className="grid md:grid-cols-4 gap-6 my-10 max-sm:my-4 mx-4">
        <div className="text-center">
          <span className="text-3xl md:text-4xl"><i className="fa-solid fa-cart-shopping"></i></span>
          <h4 className="font-semibold mt-2 text-sm md:text-base">Safe Checkout</h4>
          <p className="font-extralight mt-3 text-xs md:text-sm">Secured Data and Information</p>
        </div>
        <div className="text-center">
          <span className="text-3xl md:text-4xl"><i className="fa-solid fa-headphones"></i></span>
          <h4 className="font-semibold mt-2 text-sm md:text-base">Exceptional Customer Service</h4>
          <p className="font-extralight mt-3 text-xs md:text-sm">Customer service driven by customer-first ideology. Timings: 10.00 A.M. - 6.00 P.M.</p>
        </div>
        <div className="text-center">
          <span className="text-3xl md:text-4xl"><i className="fa-solid fa-lock"></i></span>
          <h4 className="font-semibold mt-2 text-sm md:text-base">Secure Payment</h4>
          <p className="font-extralight mt-3 text-xs md:text-sm">Pay from anywhere in India</p>
        </div>
        <div className="text-center">
          <span className="text-3xl md:text-4xl"><i className="fa-solid fa-right-left"></i></span>
          <h4 className="font-semibold mt-2 text-sm md:text-base">Easy Returns</h4>
          <p className="font-extralight mt-3 text-xs md:text-sm">Return within 3 days as per policies</p>
        </div>
      </div>

      <div className='text-center py-14 max-sm:py-6 px-6 max-sm:px-2 bg-[#ebebeb]'>
        <h1 className='text-2xl md:text-3xl font-bold'>Sign up for our newsletter</h1>
        <div className='mt-10 max-sm:mt-5'>
          <input className='py-3 ps-2 bg-transparent border border-black w-[70%] max-sm:w-[80%] max-md:w-[60%] md:w-[35%] h-10' type="text" placeholder='Your email address' />
          <button className='py-3 px-7 rounded-sm font-semibold max-sm:mt-4 max-sm:py-2 max-sm:px-3 border ms-2 border-black text-center bg-black text-white'>Subscribe</button>
        </div>
      </div>

      <div className='mt-7'>
        <h1 className='text-center text-xl md:text-2xl font-semibold underline'>Popular Searches</h1>

        <div className='px-6 md:px-32 max-sm:px-2'>
          {/** Repeat this block for different categories */}
          <div className='py-5'>
            <h1 className='underline font-semibold text-[15px]'>STITCHED SUITS</h1>
            <p className='text-[13px] font-normal mt-2'>Cotton suits | Stitched suits with dupatta | printed suits | Straight Pants Suits | Designer Suits | Salwar Suits | Anarkali Suits | Embroidered Suits | Summer Suits | cotton kurta with pants | suits with pants | Cotton kurta set | garara suit | daily wear suits | Short kurti with sharara | Printed cotton kurta | Kurti with plazzo | Unstitched suits | Chanderi Unstitched suit | Organza Unstitched suit | Semi stitched suits | Cotton dress material | Georgette semi stitched suits | Chinnon suits | Embroidery suit piece</p>
          </div>
          {/** Repeat for Kurta Set, Saree, and Lehenga as needed */}
        </div>
        <hr />
      </div>

      <footer className="bg-white pb-20 max-sm:pb-10 px-5 mt-10">
        <div className="mx-auto grid grid-cols-2 md:grid-cols-4 max-sm:grid-cols-1 gap-8">
          {/* CLIENT'S EXPERIENCE */}
          <div className='text-center'>
            <h3 className="text-base font-bold mb-4">CLIENT’S EXPERIENCE</h3>
            <ul className="space-y-2">
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Return And Exchange</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Contact Us</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Store Locator</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">About Us</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Careers</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">FAQs</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Login</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Track Order</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Blogs</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Privacy Policy</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Terms & Conditions</a></li>
            </ul>
          </div>

          {/* EXPLORE MORE */}
          <div className='text-center'>
            <h3 className="text-base font-bold mb-4">EXPLORE MORE</h3>
            <ul className="space-y-2">
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">New Arrivals</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Online Exclusive</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Sarees</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Suits</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Kurti Sets</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Lehenga</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Dresses</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Fragrances</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Collection</a></li>
            </ul>
          </div>

          {/* MY ACCOUNT */}
          <div className='text-center'>
            <h3 className="text-base font-bold mb-4">MY ACCOUNT</h3>
            <ul className="space-y-2">
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Login</a></li>
              <li className='text-[12px] hover:text-blue-900 hover:underline'><a href="#">Order History</a></li>
            </ul>
          </div>

          {/* KEEP IN TOUCH */}
          <div className='text-center'>
            <h3 className="text-base font-bold mb-4">KEEP IN TOUCH</h3>
            <div className="flex justify-center  space-x-6 max-sm:space-x-8 mb-6">
              <img className='w-6 max-sm:w-5' src={face} alt="" />
              <img className='w-6 max-sm:w-5' src={insta} alt="" />
              <img className='w-6 max-sm:w-5' src={twit} alt="" />
              <img className='w-6 max-sm:w-5' src={youtube} alt="" />
            </div>
            <h3 className="text-base font-bold mb-4">PAYMENT ACCEPTED</h3>
            <div className="flex justify-center space-x-2">
              <img className='w-10 max-sm:w-8 object-contain' src={visa} alt="" />
              <img className='w-10 max-sm:w-8 object-contain' src={mastercard} alt="" />
              <img className='w-10 max-sm:w-8 object-contain' src={amex} alt="" />
              <img className='w-10 max-sm:w-8 object-contain' src={paypal} alt="" />
              <img className='w-10 max-sm:w-8 object-contain' src={gpay} alt="" />
            </div>
          </div>
        </div>
      </footer>
      <hr />

      <p className='text-center text-[12px] py-4 text-gray-500'>@All Rights Reserved</p>
    </div>
  );
};

export default Signup;
