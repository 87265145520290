import React from 'react'
import Mycart from './Mycart/Mycart'

const Cart = () => {
  return (
    <div>
      <Mycart />
    </div>
  )
}

export default Cart
