import React from "react";
import "./Text.css";
import fir from "../../../assets/First.webp";
import sec from "../../../assets/Sec.webp";
import thrinone from "../../../assets/three-in-one.webp";

const Text = () => {
  return (
    <div>
      <div className="grid md:grid-cols-2 gap-3 px-2 mt-10">
        <div className="image-container">
          <img src={fir} alt="" className="zoom-image w-full h-auto" />
        </div>
        <div className="image-container">
          <img src={sec} alt="" className="zoom-image w-full h-auto" />
        </div>
      </div>

      <section className="flex flex-col lg:flex-row items-center px-6 lg:px-12 py-12 max-sm:py-6">
        {/* Left Image Section */}
        <div className="lg:w-1/3">
          <div className="relative">
            {/* Main Image */}
            <img
              src={thrinone}
              alt="Saree Models"
              className="h-96 w-full object-cover rounded-md" // Use object-cover for better aspect ratio
            />
          </div>
        </div>

        {/* Right Text Section */}
        <div className="lg:w-2/3 mt-8 lg:mt-0 lg:ml-12">
          <h2 className="text-xl lg:text-3xl font-bold text-gray-900 mb-4 text-center lg:text-left">
            {/* Add title here if needed */}
          </h2>
          <p className="text-gray-600 mb-6 text-center">
            <span className="font-bold text-gray-900">Established in 2020</span>
            , Srivalli Collection started as a humble store in Surat, laying the
            groundwork for a brand that would become synonymous with excellence
            in Indian ethnic fashion. In
            <strong>2023</strong>, Mr. Hitesh joined the family business,
            infusing it with fresh ideas and a bold vision. His leadership and
            strategic insights led to a phase of remarkable growth and
            transformation for the brand.
          </p>
          <p className="text-gray-600 mb-6 text-center">
            By <strong>2023</strong>, Mr. Manglani expanded the brand's
            presence. At the time, Srivalli Collection had just two stores,
            including the notable location in South Extension.
          </p>
          <p className="text-gray-600 mb-6 text-center">
            Today, Srivalli Collection is celebrated for its quality, elegance,
            and authenticity in Indian ethnic fashion. The brand skillfully
            combines traditional craftsmanship with modern design elements,
            setting trends and captivating customers with its timeless
            creations.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Text;
