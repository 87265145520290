import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";


const Password = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSubscribed, setIsSubscribed] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
    
        // Basic validation
        if (!email || !password) {
          alert("Please fill in all required fields.");
          return;
        }
    
        // Handle the registration logic here
        console.log({
          email,
          password,
          isSubscribed,
        });
    
        // Optionally, reset the form
        setEmail("");
        setPassword("");
        setIsSubscribed(false);
    
        navigate("/");
      };

      const handleSub = () => {
        navigate("/");
      }

  return (
    <>
    <div className="px-44 max-sm:px-3">
      <div className="container mx-auto py-4 flex gap-2">
          <p
            className="text-gray-500 cursor-pointer hover:text-black"
            onClick={() => navigate("/")}
          >
            Home
          </p>
          <span>
            <i class="fa-solid fa-chevron-right"></i>
          </span>
          <p>Login</p>
        </div>

        <h1 className="text-2xl font-normal mt-6">Login</h1>

        <p className='mt-3'>Reset your password</p>

        <div className="w-1/3 max-sm:w-full">
          <form onSubmit={handleSubmit} action="" className="mt-6">
            
            <span className="flex flex-col space-y-2 my-3">
              <label htmlFor="" className="font-semibold">
                Your Email Address <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border border-gray-400 py-2"
                required
              />
            </span>
            <span className="flex flex-col space-y-2 my-3">
              <label htmlFor="" className="font-semibold">
                New Password <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border border-gray-400 py-2"
                required
              />
            </span>

            <span className="flex flex-col space-y-2 my-3">
              <label htmlFor="" className="font-semibold">
                Confirm Password <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border border-gray-400 py-2"
                required
              />
            </span>

            <span>
              <input
                type="checkbox"
                checked={isSubscribed}
                onChange={() => setIsSubscribed(!isSubscribed)}
              />
              Subscribe to email marketing
            </span>
          </form>

          <button onClick={handleSub}  type="submit" className="mt-10 bg-[#393838] text-white px-14 py-3 rounded-md hover:bg-white border hover:border-[#393838] hover:text-[#393838] transition duration-300">
            Submit
          </button>

          
        </div>
    </div>
      
      
    </>
  )
}

export default Password
