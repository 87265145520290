import React from 'react'
import PayOption from '../../assets/pay-option.png'
import { useLocation, useNavigate } from "react-router-dom";

const Ppage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { products = [], totalPrice = 0 } = location.state || {};

    const handlePayments = () => {
      navigate("/payments", { state: { totalPrice } }) 
    }

  return (
    <>
    <div>
    <div className="flex flex-col md:flex-row justify-between p-4 max-sm:p-2 bg-gray-100 min-h-screen">
      {/* Left Section (Account & Delivery Form) */}
      <div className="w-full md:w-1/2 bg-white p-6 shadow-md rounded-lg">
        {/* Account Info */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold">Account</h2>
          <p className="text-gray-600">jaytimbadiya21@gmail.com</p>
        </div>
        
        <hr className="my-4" />

        {/* Delivery Form */}
        <h2 className="text-2xl font-semibold mb-4">Delivery</h2>
        <form className="space-y-4">
          {/* Country/Region */}
          <div>
            <label className="block mb-1 text-gray-700">Country/Region</label>
            <input 
              type="text" 
              defaultValue="India" 
              className="block w-full p-2 border border-gray-300 rounded" 
              readOnly
            />
          </div>
          {/* First and Last Name */}
          <div className="flex space-x-4">
            <div className="w-1/2">
              <label className="block mb-1 text-gray-700">First name</label>
              <input 
                type="text" 
                className="block w-full p-2 border border-gray-300 rounded" 
                placeholder="First name" 
              />
            </div>
            <div className="w-1/2">
              <label className="block mb-1 text-gray-700">Last name</label>
              <input 
                type="text" 
                className="block w-full p-2 border border-gray-300 rounded" 
                placeholder="Last name" 
              />
            </div>
          </div>
          {/* Address */}
          <div>
            <label className="block mb-1 text-gray-700">Address</label>
            <input 
              type="text" 
              className="block w-full p-2 border border-gray-300 rounded" 
              placeholder="Address" 
            />
          </div>
          {/* Apartment, Suite */}
          <div>
            <label className="block mb-1 text-gray-700">Apartment, suite, etc. (optional)</label>
            <input 
              type="text" 
              className="block w-full p-2 border border-gray-300 rounded" 
              placeholder="Apartment, suite, etc." 
            />
          </div>
          {/* City, State, and PIN */}
          <div className="flex space-x-4">
            <div className="w-1/2">
              <label className="block mb-1 text-gray-700">City</label>
              <input 
                type="text" 
                className="block w-full p-2 border border-gray-300 rounded" 
                placeholder="City" 
              />
            </div>
            <div className="w-1/4">
              <label className="block mb-1 text-gray-700">State</label>
              <input 
                type="text" 
                defaultValue="Gujarat" 
                className="block w-full p-2 border border-gray-300 rounded" 
                readOnly
              />
            </div>
            <div className="w-1/4">
              <label className="block mb-1 text-gray-700">PIN code</label>
              <input 
                type="text" 
                className="block w-full p-2 border border-gray-300 rounded" 
                placeholder="PIN code" 
              />
            </div>
          </div>
          {/* Phone */}
          <div>
            <label className="block mb-1 text-gray-700">Phone</label>
            <input 
              type="text" 
              className="block w-full p-2 border border-gray-300 rounded" 
              placeholder="Phone number" 
            />
          </div>
        </form>
      </div>

      {/* Right Section (Order Summary) */}
      <div className="w-full md:w-1/3 bg-white p-6 mt-8 md:mt-0 shadow-md rounded-lg bg-fixed">
            <h2 className="font-semibold text-lg border-b pb-4">ORDER SUMMARY</h2>

            {/* Loop through products and display each product */}
            {products.map((product, index) => (
              <div className="flex justify-between items-center mb-4" key={index}>
                <img 
                  src={product.img} 
                  alt={product.name} 
                  className="w-16 h-16 object-contain rounded-md"
                />
                <div className="flex-1 mx-4">
                  <p className="text-gray-700 font-semibold">{product.name}</p>
                  <p className="text-gray-500 text-sm">Size: {product.selectedSize}</p>
                </div>
                <p className="font-semibold">₹{product.price}</p>
              </div>
              ))}
        
        {/* Discount Code */}
        <div className="flex items-center mb-4">
          <input 
            type="text" 
            placeholder="Discount code or gift card" 
            className="w-full p-2 border border-gray-300 rounded"
          />
          <button className="ml-2 px-4 py-2 bg-gray-200 text-gray-600 font-semibold rounded">
            Apply
          </button>
        </div>

        {/* Price Details */}
        <div className="text-sm text-gray-600">
              <div className="flex justify-between mb-2">
                <span>Subtotal</span>
                <span>₹{totalPrice}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span>Shipping</span>
                <span>Enter shipping address</span>
              </div>
            </div>

            {/* Total Price */}
            <div className="text-xl font-bold flex justify-between">
              <span>Total</span>
              <span>INR ₹{totalPrice}</span>
            </div>

            <div className="mt-6">
              <img src={PayOption} alt="Payment Options" />
            </div>
          </div>
    </div>

    <div className='text-center mb-4' onClick={handlePayments}>
        <button className='bg-blue-800 text-white font-bold py-3 px-20 rounded-md mt-4 hover:bg-blue-600 '>Pay now</button>
    </div>
    <hr />
    </div>
    </>
  )
}

export default Ppage
